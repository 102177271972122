const Colors = {
    white: '#ffffff',
    light: '#edf2f6',
    lightGrey: '#dae2e5',
    dark: '#97a7b5',
    darker: '#50616a',
    darkest: '#1d2a30',
    closeIcon: '#969ea4'
  }
  
  export default Colors;